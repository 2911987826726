import { Link } from "react-router-dom";
import { Task, User } from "../../../types";
import Container from "./layout/Sub-Mystery-Container";

export default function Mystery({ task, users, setTasks }: { task: Task, users: User[], setTasks: React.Dispatch<React.SetStateAction<Task[]>> }) {
    return <Container task={task} setTasks={setTasks}>
        <div className="info-with-value">
            <div className="info-data">📄 Текст задания </div>
            <div className="value-data">{task.desc}</div>
        </div>
        <div className="info-with-value">
            <div className="info-data">📸 Картинка загадки </div>
            <div className="value-data"><img src={`/images/${task.image}`} alt="" /> </div>
        </div>
        <div className="info-with-value">
            <div className="info-data">💰 Вознаграждение: </div>
            <div className="value-data -green-info">{task.reward} фишек </div>
        </div>
        <div className="info-with-value">
            <div className="info-data">Ответ на загадку: </div>
            <div className="value-data -green-info">{task.answer} </div>
        </div>
        <div className="info-with-value">
            <div className="info-data">✅ Игроки выполнившие задания: </div>
            <div className="value-data">{users.filter(user => user.completedTasks.find(t => t.parentTaskUuid === task.uuid)).length} </div>
        </div>
        <Link to={`/admp/tasks/completedsubmystery/${task.uuid}`} className="btn-s-users mt10">Просмотреть список</Link>
        <Link to="/admp/tasks/create/mystery" className="-save-btn mt40">Заменить загадку</Link>
    </Container>
}