import { useCallback, useEffect, useState } from "react";
import { Task, User as UserType } from "../../types";
import User from "../../components/admin/users/User";
import { Link, useParams } from "react-router-dom";
import FilterByName from "../../components/admin/users/FilterByName";
import Pagination from "../../components/admin/Pagination";
import { useFetchUsers } from "../../hooks/useFetchUsers";
import { toast } from "react-toastify";
import TasksContoller from "../../api/admin/tasks";

export default function CompletedFarmInvite() {
    const { taskKey } = useParams();
    const [users, setUsers] = useState<UserType[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<UserType[]>([]);
    const [paginatedUsers, setPaginatedUsers] = useState<UserType[]>([]);
    const [tasks, setTasks] = useState<Task[]>([]);

    useEffect(() => {
        const getTasks = async () => {
            const response = await TasksContoller.getTasks();

            if (response.error) return toast(response.message);

            setTasks(response.tasks.filter(task => task.active && task.key === taskKey));
        }

        getTasks();
    }, [taskKey]);

    const getUsers = useCallback((allUsers: UserType[]) => {
        const filterByInviter = allUsers.filter(user => user.completedTasks.find(completedTask => tasks.find(task => task.quantity === completedTask.quantity && task.uuid === completedTask.parentTaskUuid)));

        setUsers(filterByInviter);

        setFilteredUsers(filterByInviter);
    }, [tasks]);

    const { allUsers, setAllUsers } = useFetchUsers(getUsers);

    return <section className="main-part-dinamic">
        <div className="wrap-of-dinamic">
            <div className="up-part-of-dinamic">
                <Link to="/admp/tasks" className="btn-s-gradient">
                    <span>&lt; Назад</span>
                </Link>
            </div>
            <div className="dinamic-content -cmr-users -other-pages-content">

                <div className="additional-wrap-of-crm">
                    <div className="current-data mt10">
                        <span>Найдено:</span>
                        <span className="-yellow-info">{users.length}</span>
                    </div>
                </div>

                <FilterByName users={users} filteredUsers={filteredUsers} setFilteredUsers={setFilteredUsers} allUsers={allUsers} />

                <div className="list-of-users">
                    {paginatedUsers.map(user => {
                        return <User user={user} key={user.tg_id} setAllUsers={setAllUsers} />
                    })}
                </div>

                <Pagination filteredUsers={filteredUsers} setPaginatedUsers={setPaginatedUsers} />
            </div>
        </div>
    </section>
}