import { http } from ".";
import { ErrorResponseType, UserResponseType, UserTimerResponseType, UserTopResponseType } from "../types";

const getUser = async (id: string): Promise<UserResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.get(`/users/${id}`);
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const increaseBalance = async (id: string, amount: number): Promise<UserResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.put(`/users/${id}/increase_balance`, { amount });
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const getTopPlayers = async (): Promise<UserTopResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.get('/top-users');
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const increaseSpentTime = async (id: string, seconds: number): Promise<UserTimerResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.put(`/users/${id}/time_spent`, { seconds });
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const updateSubModalInfo = async (id: string): Promise<{ error: false, message: string } | ErrorResponseType> => {
    try {
        const { data } = await http.put(`/users/${id}/sub-modal-info`);
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const setWallet = async (id: string, wallet: string): Promise<{ error: false, message: string } | ErrorResponseType> => {
    try {
        const { data } = await http.put(`/users/${id}/wallet`, { wallet });
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const UserController = {
    getUser,
    increaseBalance,
    getTopPlayers,
    increaseSpentTime,
    updateSubModalInfo,
    setWallet
};

export default UserController;