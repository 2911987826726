import { useEffect, useRef } from "react";
import AutoFarmController from "../../../api/autofarm";
import { useError } from "../../../context/ErrorContext";
import { useUser } from "../../../context/UserContext";
import { useAutoFarm } from "../../../context/AutoFarmContext";
import poker from "../../../assets/img/icons/poker.svg";
import { formatTime } from "../../../utils";

export default function AutoFarm() {
    const { tg_id, setUser, user } = useUser();
    const { setError } = useError();
    const { autoFarmStarted, setAutoFarmStarted, getAutoFarmStatus } = useAutoFarm();
    const hasFetchedInitially = useRef(false);

    useEffect(() => {
        if (!tg_id) return;

        let interval: NodeJS.Timeout;

        if (autoFarmStarted) {
            if (!hasFetchedInitially.current) {
                getAutoFarmStatus(tg_id);
                hasFetchedInitially.current = true;
            }

            interval = setInterval(async () => {
                await getAutoFarmStatus(tg_id);
            }, 2000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [tg_id, autoFarmStarted, setAutoFarmStarted, setError, setUser, getAutoFarmStatus]);

    const startAutoFarm = async () => {
        if (!tg_id) return setError("Вы не авторизованы");

        const response = await AutoFarmController.startAutoFarm(tg_id);

        if (response.error) return setError(response.message);

        setAutoFarmStarted(response.status);
    };

    if (!user) return null;

    return <div className="block-info -aic -show-autofarm active" style={{ position: "relative" }}>
        <div className="left-part" style={{ zIndex: 2 }}>
            <div className="icon-div -w24px">
                <img src={poker} alt="" />
            </div>
            <div className="main-txt -fz14">
                Авто фарм фишек
            </div>
        </div>

        <div className="decor-icon -w100proc" style={{ position: "absolute", zIndex: 1 }}>
            <svg id="_Слой_1" data-name=" Слой 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 489.9 473.8">
                <g id="_10_Poker" data-name=" 10 Poker">
                    <path className="cls-1" d="M173.7,203.9l-11.7-11.7-57.7-57.5c-5.8-5.8-15.1-5.8-20.9,0l-57.5,57.3-11.8,11.8c-18.8,18.8-18.8,49.2,0,68,15.9,15.9,40.7,18.6,59.7,6.6l-6.5,35.2c-1.2,6.2,3,12.2,9.2,13.4.7.1,1.4.2,2.1.2h30.6c6.3,0,11.5-5.1,11.5-11.5s0-1.4-.2-2.1l-6.5-35.4c19,12,43.8,9.3,59.8-6.6,18.7-18.6,18.7-48.9,0-67.6h-.1q0-.1,0-.1Z" />
                    <path className="cls-1" d="M165.2,14.1c18.8-18.8,49.3-18.8,68.1,0l11.8,11.8,11.7-11.7c18.8-18.7,49.2-18.7,68,0,18.7,18.6,18.8,48.9.1,67.6,0,0-.1,0-.1.2l-11.7,11.7-57.7,57.5c-5.8,5.8-15.1,5.8-20.9,0l-57.5-57.3-11.8-11.8c-18.8-18.7-18.8-49-.2-67.7,0,0,.1-.1.2-.2h0Z" />
                    <path className="cls-1" d="M334.7,387.2c4-2.4,5.4-7.6,3-11.6-.7-1.2-1.8-2.3-3-3-34-20-62.4-48.4-82.4-82.4-2.4-4-7.6-5.4-11.7-3-1.2.7-2.2,1.8-3,3-20.1,33.9-48.5,62.3-82.4,82.4-4,2.4-5.4,7.6-3,11.6.7,1.2,1.8,2.3,3,3,34,20,62.4,48.4,82.4,82.4,2.4,4,7.6,5.4,11.6,3,1.2-.7,2.3-1.8,3-3,20.1-34,48.4-62.3,82.4-82.4h0Z" />
                    <path className="cls-1" d="M441.1,193.4h-1.1c8.5-25.6-5.4-53.3-31-61.8-25.6-8.5-53.3,5.4-61.8,31.1-3.3,10-3.3,20.8,0,30.8-26.9,2.1-47,25.6-45,52.5,2.1,26.9,25.6,47.1,52.5,45,5.2-.4,10.3-1.6,15.1-3.6l-4.7,25.3c-1.4,7.2,3.4,14.2,10.6,15.5.8.1,1.6.2,2.4.2h35.5c7.4,0,13.3-6,13.3-13.3,0-.8,0-1.6-.2-2.4l-4.7-25.3c24.9,10.4,53.5-1.4,63.9-26.3s-1.4-53.5-26.3-63.9c-5.9-2.5-12.3-3.8-18.7-3.8h0q0,0,0,0Z" />
                </g>
            </svg>
        </div>
        {!autoFarmStarted ?
            <button className="btn-action -start-autofarm" style={{ zIndex: 2 }} onClick={startAutoFarm}>
                <div className="signature-btn-action">
                    Запустить
                </div>
                <div className="will-added value-btn-action">
                    <span>+ {user.level.multiplier * 10000}</span>
                    <div className="coin-icon -w14"></div>
                </div>
            </button> :
            <div className="autofarm active">
                {autoFarmStarted.endTime > 0 && <div className="timer-for-autofarm">
                    {formatTime(autoFarmStarted.endTime)}
                </div>}
                {autoFarmStarted.lastReward > 0 && <div className="will-added value-btn-action">
                    + {autoFarmStarted.lastReward.toFixed(3)}
                    <div className="coin-icon -w14"></div>
                </div>
                }
            </div>
        }
    </div>
}