// import { useEffect } from "react";
import { useUser } from "../../../context/UserContext";
import useTopUsers from "../../../hooks/useTopUsers";
import Error from "../Error";
import Header from "./Header";
import PlayerList from "./PlayerList";
import { usePreventCollapse } from "../../../hooks/usePreventCollapse";

export default function Players() {
    // const { user, getUser } = useUser();
    const { user } = useUser();
    const { topUsers, error } = useTopUsers();
    usePreventCollapse();

    // useEffect(() => {
    //     getUser();
    // }, [getUser]);

    if (!user || !topUsers) return null;

    if (error) return <Error>{error}</Error>;

    return <>
        <Header />

        <div className="inner-main-part -aifs -players">
            <PlayerList players={user.friends} label="Мои" />

            <PlayerList players={topUsers} label="Топ" />
        </div>
    </>
}