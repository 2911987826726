import { useCallback, useState } from "react";
import { Task } from "../../../types";
import { toast } from "react-toastify";
import TasksContoller from "../../../api/admin/tasks";

export default function FarmInviteChangeContainer({ task, index, setTasks }: { task: Task, index: number, setTasks: React.Dispatch<React.SetStateAction<Task[]>> }) {
    const [reward, setReward] = useState<string>(task.reward.toString());
    const [quantity, setQuantity] = useState<string>(task.quantity!.toString());

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const response = await TasksContoller.editFarmInviteTask({
            key: task.key,
            uuid: task.uuid,
            reward,
            quantity
        });

        if (response.error) return toast(response.message);

        return toast(response.message);
    };

    const complete = useCallback(async () => {
        const response = await TasksContoller.completeTask(task.uuid);

        if (response.error) return toast(response.message);

        setTasks(response.tasks.filter(t => t.key === task.key));

        toast("Задание завершено");
    }, [task, setTasks]);

    const restore = useCallback(async () => {
        const response = await TasksContoller.restoreTask(task.uuid);

        if (response.error) return toast(response.message);

        setTasks(response.tasks);

        toast("Задание возобновлено");
    }, [task, setTasks]);


    return <form className={`-profile-setting mt40 ${!task.active ? "profit-item p0 b0" : ""}`} key={task.uuid} onSubmit={handleSubmit}>
        <div className={`right-part-of-secondary-pages ${!task.active ? "w100proc" : ""}`}>
            <div className="dark-block-content -full-bg">
                <div className="second-others-inner-header" style={{ marginTop: "0" }}>
                    Отметка {index + 1}
                </div>

                <div className="input-wrap  -valid-input">
                    <div className="header-of-input">
                        {task.key === "farm" ? "🃏 Количество зафармленных фишек" : "👥 Количество приглашенных"}
                    </div>
                    <input type="text" value={quantity} onChange={event => setQuantity(event.target.value)} />
                    <div className="error-input">
                        Вы допустили ошибку
                    </div>
                </div>
                <div className="input-wrap  -valid-input">
                    <div className="header-of-input">
                        💰 Вознаграждение
                    </div>
                    <input type="text" value={reward} onChange={event => setReward(event.target.value)} />
                    <div className="error-input">
                        Вы допустили ошибку
                    </div>
                </div>
                <button type="submit" className="-save-btn mt20" style={{ marginTop: "30px" }}>Сохранить</button>
                {task.active ?
                    <button type="button" className="btn-s-users mt20 -red-btn-users" onClick={complete}>Завершить задание</button> :
                    <button type="button" className="btn-s-users mt20" onClick={restore}>Возобновить задание</button>
                }
            </div>
        </div>
    </form>
}