import { useLevels } from "../../../context/LevelsContext";
import { useUser } from "../../../context/UserContext";
import { LevelsType } from "../../../types";

interface ProgressProps {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Progress({ setShowModal }: ProgressProps) {
    const { user } = useUser();
    const { levels } = useLevels();

    if (!user || !levels) return null;

    return <>
        <div className="progress-line">
            <div className="actual-progress" style={{ width: `${user.levelKey !== "diler_legenda" ? getRange(levels, user.level.user_level, user.balance).percentageProgress : 100}% ` }}></div>
        </div >
        <div className="data-of-level">
            <div className="text-btn -second-style -open-level-decks" onClick={() => setShowModal(true)}>
                {levels!.find(level => level.key === user!.levelKey)?.deck_name} <span>{`> `}</span>
            </div>
            {user.levelKey !== "diler_legenda" && <div className="actual-level">
                {getRange(levels, user.level.user_level, user.balance).level}<span>/10</span>
            </div>
            }
        </div>
    </>
}

function getRange(levels: LevelsType, user_level: number, balance: number) {
    // Найти максимальный и минимальный баланс для данного уровня пользователя
    const currentLevel = levels.find(level => level.user_level === user_level);

    const maxBalance = currentLevel!.end_balance;
    const minBalance = currentLevel!.start_balance;

    // Определить диапазон одного уровня
    const totalRange = maxBalance - minBalance;
    const levelRange = totalRange / 10;

    // Рассчитать уровень
    let level = Math.floor((balance - minBalance) / levelRange) + 1;

    // Убедиться, что уровень не превышает 10
    if (level > 10) {
        level = 10;
    }

    // Рассчитать процентное соотношение текущего прогресса в уровне
    const progressWithinLevel = ((balance - minBalance) % levelRange) / levelRange;
    const percentageProgress = (level - 1) * 10 + progressWithinLevel * 10;

    return {
        level, percentageProgress
    }
}