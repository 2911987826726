import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { User, UserContextType } from "../types";
import { useSearchParams } from "react-router-dom";
import UserController from "../api/user";
import { useError } from "./ErrorContext";

const UserContext = createContext<UserContextType>(null!);

export function UserProvider({ children }: { children: React.ReactNode }) {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState<User | null>(null);
    const { setError } = useError();
    const [currentGameReward, setCurrentGameReward] = useState(0);
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');

    const getUser = useCallback(async () => {
        if (!id) return;
        const response = await UserController.getUser(id);

        // setLoading(false);

        if (response.error) return setError(response.message);

        setUser(response.user);

        setCurrentGameReward(response.user.balance - response.user.completedTasks.reduce((acc, task) => acc + task.reward, 0) - response.user.inviteRewards);
        
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }, [id, setError]);


    useEffect(() => {
        getUser();
    }, [id, setError, getUser]);

    return <UserContext.Provider value={{ user, loading, setLoading, setUser, tg_id: user?.tg_id, currentGameReward, setCurrentGameReward, getUser }}>{children}</UserContext.Provider>;
}

export function useUser() {
    return useContext(UserContext);
}
