import { Link } from "react-router-dom";
import { Task, User } from "../../../types";
import Container from "./layout/Sub-Mystery-Container";

export default function Sub({ task, users, setTasks }: { task: Task, users: User[], setTasks: React.Dispatch<React.SetStateAction<Task[]>> }) {
    return <Container task={task} setTasks={setTasks}>
        <div className="info-with-value">
            <div className="info-data">📄 Название ТГ канала / группы </div>
            <div className="value-data">{task.desc}</div>
        </div>
        <div className="info-with-value">
            <div className="info-data">🔗 Ссылка </div>
            <div className="value-data">{task.answer}</div>
        </div>
        <div className="info-with-value">
            <div className="info-data">📸 Logo </div>
            <div className="value-data w40px"><img src={`/images/${task.icon}`} alt="" /> </div>
        </div>
        <div className="info-with-value">
            <div className="info-data">📸 Картинка баннер </div>
            <div className="value-data"><img src={`/images/${task.image}`} alt="" /> </div>
        </div>
        <div className="info-with-value">
            <div className="info-data">💰 Вознаграждение: </div>
            <div className="value-data -green-info">{task.reward} фишек </div>
        </div>
        <div className="info-with-value">
            <div className="info-data">✅ Игроки выполнившие задание: </div>
            <div className="value-data">{users.filter(user => user.completedTasks.find(t => t.parentTaskUuid === task.uuid && t.answer === task.answer)).length}</div>
        </div>
        <Link to={`/admp/tasks/completedsubmystery/${task.uuid}`} className="btn-s-users mt10">Просмотреть список</Link>
        <Link to={`/admp/tasks/change/sub/${task.uuid}`} className="-save-btn mt40">Изменить задание</Link>
    </Container>
}