interface SwipeRewardProps {
    currentGameReward: number;
    currentCardReward: number;
}

export default function SwipeReward({ currentGameReward, currentCardReward }: SwipeRewardProps) {
    return <div className="big-coins-amoult">
        <div className="coin-icon -w34"></div>
        <span>{currentGameReward}</span>
        <div className="added-amoult-text">
            <div className="value-for-amoult">+{currentCardReward}</div>
        </div>
    </div>
}