import CardItem from "./CardItem";

interface CardProps {
    cards: {
        type: string;
        value: string;
        coins: number;
    }[]
}

export default function CardList({ cards }: CardProps) {
    return <div className="demo">
        <div className="demo__content">
            <div className="demo__card-cont">
                {cards.map((card, index) => (
                    <CardItem card={card} key={index} />
                ))}
            </div>
        </div>
    </div>
}