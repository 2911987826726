import { useTabs } from "../../../context/TabsContext";
import { TabsType } from "../../../types";
import Cards from "../cards/Cards";
import Players from "../players/Players";
import Tasks from "../tasks/Tasks";

export default function ActiveTab() {
    const { activeTab } = useTabs();

    const renderTab = (tab: TabsType) => {
        switch (tab) {
            case 'cards':
                return <Cards />;
            case 'tasks':
                return <Tasks />;
            case 'players':
                return <Players />;
            default:
                return null
        }
    }

    return renderTab(activeTab);
}