import { useEffect, useState } from "react";
import Input from "../../components/admin/tasks/inputs/Input";
import SettingsController from "../../api/admin/settings";
import { toast } from "react-toastify";

export default function Settings() {
    const [bonus, setBonus] = useState("");

    const getSettings = async () => {
        const response = await SettingsController.getSettings();

        if (response.error) return toast(response.message);

        setBonus(response.settings.refBonus?.toString() ?? "");
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const response = await SettingsController.updateBonus(bonus);

        if (response.error) return toast(response.message);

        return toast(response.message);
    }

    useEffect(() => {
        getSettings();
    }, []);

    return <section className="main-part-dinamic">
        <div className="wrap-of-dinamic">
            <div className="dinamic-inner-header mb40">
                <div>
                    <div>⚠️ ВНИМАНИЕ! Лучше всего вносить изменения вне пика активности.</div>

                    <br />

                    <div>Корректировки не вносятся в предыдущие достижения.</div>

                </div>
            </div>
            <div className="main-header-of-page">
                Настройки
            </div>

            <form className="dinamic-content -cmr-users -other-pages-content" onSubmit={handleSubmit}>
                <div className="-profile-setting mt40">
                    <div className="right-part-of-secondary-pages">
                        <div className="dark-block-content -full-bg">
                            <div className="second-others-inner-header" style={{ marginTop: "0" }}>
                                Бонус за переход по реферальной ссылке на стартовый баланс
                            </div>

                            <Input title="Количество фишек" state={bonus} setState={setBonus} placeholder="Укажите значение реф бонуса" />
                            <button type="submit" className="-save-btn mt20" style={{ marginTop: "30px" }}>Обновить</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
}