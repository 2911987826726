import { ComboInfo } from "../../../utils";

interface ComboProps {
    comboInfo: ComboInfo | null;
}

export default function Combo({ comboInfo }: ComboProps) {
    return <>
        {comboInfo ?
            <div className="combo-part-wrap active">
                <div className="combo-block">
                    <div className="inner-value">
                        {comboInfo?.name}
                        <span>+{comboInfo?.reward}</span>
                    </div>
                </div>
            </div> : null}
    </>
}