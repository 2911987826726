import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

export default function Layout() {
    const location = useLocation();
    const [stylesLoaded, setStylesLoaded] = useState(false);

    useEffect(() => {
        if (!location.pathname.startsWith("/admp") && !stylesLoaded) {
            // require("../user.css")
            const link = document.createElement("link");
            link.rel = "stylesheet";
            link.href = "/assets/user.css";
            document.head.appendChild(link);
        }

        setStylesLoaded(true);

        return () => {
            setStylesLoaded(false);
        };
    }, [location.pathname, stylesLoaded])

    if (!stylesLoaded) {
        return <div></div>;
    }

    return <Outlet />;
}
