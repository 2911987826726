export default function Status({ reward, onClick }: { reward: number, onClick?: () => void }) {
    return <>
        <div className="done-action">
            <div className="signature-btn-action">
                Получил
            </div>
            <div className="will-added value-btn-action">
                + {reward}
                <div className="coin-icon -w14"></div>
            </div>
        </div>
        <div className="btn-action -open-guess-a-riddle" onClick={onClick}>
            <div className="signature-btn-action">
                Выполнить
            </div>
            <div className="will-added value-btn-action">
                + {reward}
                <div className="coin-icon -w14"></div>
            </div>
        </div>
    </>
}