import { Link } from "react-router-dom";
import { User as UserType } from "../../../types";
import { copyToClipboard, formatDate, formatTime, getUserLevel } from "../../../utils";
import { toast } from "react-toastify";
import UsersContoller from "../../../api/admin/users";

export default function User({ user, setAllUsers }: { user: UserType, setAllUsers: React.Dispatch<React.SetStateAction<UserType[]>> }) {
    const ban = async () => {
        const response = await UsersContoller.banUser(user.tg_id);

        if (response.error) return toast(response.message);

        toast("Игрок заблокирован");

        setAllUsers(response.users);
    };

    const unban = async () => {
        const response = await UsersContoller.unbanUser(user.tg_id);

        if (response.error) return toast(response.message);

        toast("Игрок разблокирован");

        setAllUsers(response.users);
    };

    return <div className="user-item-of-list active">
        <div className="up-part-of-user-info">
            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>ID telegram</span>
                    <span className="-blue-info">{user.tg_id}</span>
                </div>
            </div>
            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>Монет-фишек</span>
                    <span className="-green-info">{user.balance}</span>
                </div>
            </div>
            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>username</span>
                    <span>{user.tg_username}</span>
                </div>
            </div>
            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>name TG</span>
                    <span>{user.tg_firstname} {user.tg_lastname}</span>
                </div>
            </div>
            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>Зашел в игру</span>
                    <span>{formatDate(user.created_at)}
                        {user.invitedBy && <div className="-blue-info">от {user.invitedBy}</div>}
                    </span>
                </div>
            </div>
            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>Уровень</span>
                    <span>{user.level.level_name} {getUserLevel(user.level.user_level, user.balance)}</span>
                </div>
            </div>
        </div>
        <div className="down-part-of-user-info">
            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>Выполнил заданий</span>
                    <span className="-yellow-info">{user.completedTasks.length}</span>
                </div>
            </div>
            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>Привел игроков</span>
                    <span className="-yellow-info">{user.friends.length}</span>
                </div>

                {user.friends.length > 0 && <Link to={`/admp/invitations/${user.tg_id}/`} className="btn-s-users">Просмотреть</Link>}
            </div>
            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>Часов в игре</span>
                    <span className="-blue-info">{formatTime(user.timeSpent * 60 * 1000)}</span>
                </div>
            </div>

            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>Адрес кошелька TON</span>
                    <span className="-blue-info">{user.wallet}</span>
                </div>

                {user.wallet && <button className="btn-s-users" onClick={() => { copyToClipboard(user.wallet || "") }} >Копировать</button>}
            </div>

            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>TG игрока</span>
                </div>
                <a href={`https://t.me/${user.tg_username}`} className="btn-s-users" target="_blank" rel="noreferrer">Написать в ЛС</a>

                {user.banned ?
                    <button className="btn-s-users mt20" onClick={unban}>
                        Разблокировать игрока
                    </button> :
                    <button className="btn-s-users mt20 -red-btn-users" onClick={ban}>
                        Заблокировать игрока
                    </button>
                }
            </div>
        </div>
    </div>
}