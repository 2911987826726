import exchange from "../../../assets/img/icons/exchange.svg";

export default function StartGameHint() {
    return <div className="block-info -demo-start">
        <div className="icon-div -w18px">
            <img src={exchange} alt="" />
        </div>
        <div className="main-txt -fz14">
            Свайпай карты, выбивай комбинации и получай монеты!
        </div>
        <div className="decor-icon -w100px">
            <svg id="_Слой_1" data-name=" Слой 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 447.7 512">
                <path className="cls-1" d="M235.6,203.5v25.7c0,8.2,1.6,15,4.9,20.4,4.4,7.4,11.7,11.6,20,11.6s16.2-4,24-11.7l85-85c18.7-18.7,18.7-49,0-67.7L284.5,11.7c-7.8-7.8-15.9-11.7-24-11.7-12.4,0-24.9,9.9-24.9,32v23.4C114.9,62.5,15,155.1.1,276.4c-.8,6.8,3,13.3,9.4,15.8,1.8.7,3.7,1,5.5,1,4.7,0,9.2-2.2,12.1-6.1,39.8-54.4,103.8-86.9,171.1-86.9s25,1.1,37.4,3.4h0Z" />
                <path className="cls-1" d="M438.2,219.8c-6.4-2.5-13.6-.4-17.6,5.1-39.8,54.4-103.8,86.9-171.1,86.9s-25-1.1-37.4-3.4v-25.7c0-22.1-12.5-32-24.9-32s-16.2,3.9-24,11.7l-85,85c-18.7,18.7-18.7,49,0,67.7l85,85c7.8,7.8,15.9,11.7,24,11.7,12.4,0,24.9-9.9,24.9-32v-23.4c120.7-7.1,220.6-99.8,235.5-221.1.8-6.8-3-13.3-9.4-15.8h0Z" />
            </svg>
        </div>
    </div>
}