import http from "./index";
import { CreateTaskResponseType, CreateMysteryTaskType, CreateSubTaskType, ErrorResponseType, TaskResponseType, EditSubTaskType, EditTaskResponseType, EditFarmInviteTaskType, CreateFarmInviteTaskType, AdminTasksResponseType, InactiveSubTasksResponseType } from "../../types";

const getTasks = async (): Promise<AdminTasksResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.get(`/admin/tasks/`);
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const getTask = async (uuid: string): Promise<TaskResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.get(`/admin/tasks/${uuid}`);
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const getInactiveSubTasks = async (): Promise<InactiveSubTasksResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.get(`/admin/tasks/inactivesubs`);
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const completeTask = async (uuid: string): Promise<AdminTasksResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.put(`/admin/tasks/complete/${uuid}`);
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const restoreTask = async (uuid: string): Promise<AdminTasksResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.put(`/admin/tasks/restore/${uuid}`);
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const createMysteryTask = async (data: CreateMysteryTaskType): Promise<CreateTaskResponseType | ErrorResponseType> => {
    try {
        const formData = new FormData();
        formData.append("banner", data.banner);
        formData.append("reward", data.reward);
        formData.append("desc", data.text);
        formData.append("answer", data.answer);

        const response = await http.post(`/admin/tasks/create/mystery`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error: any) {
        return error.response.data;
    }
};

const createSubTask = async (data: CreateSubTaskType): Promise<CreateTaskResponseType | ErrorResponseType> => {
    try {
        const formData = new FormData();
        formData.append("icon", data.icon);
        formData.append("banner", data.banner);
        formData.append("reward", data.reward);
        formData.append("desc", data.text);
        formData.append("answer", data.answer);
        formData.append("modalTitle", data.modalTitle);

        const response = await http.post(`/admin/tasks/create/sub`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error: any) {
        return error.response.data;
    }
};

const editSubTask = async (data: EditSubTaskType): Promise<EditTaskResponseType | ErrorResponseType> => {
    try {
        const formData = new FormData();
        formData.append("icon", data.icon);
        formData.append("banner", data.banner);
        formData.append("desc", data.text);
        formData.append("reward", data.reward);
        formData.append("answer", data.answer);
        formData.append("modalTitle", data.modalTitle);

        const response = await http.put(`/admin/tasks/edit/sub/${data.uuid}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error: any) {
        return error.response.data;
    }
};

const editFarmInviteTask = async (data: EditFarmInviteTaskType): Promise<EditTaskResponseType | ErrorResponseType> => {
    try {
        const response = await http.put(`/admin/tasks/edit/farminvite/${data.uuid}`, {
            key: data.key,
            reward: data.reward,
            quantity: data.quantity,
        });

        return response.data;
    } catch (error: any) {
        return error.response.data;
    }
};

const createFarmInviteTask = async (data: CreateFarmInviteTaskType): Promise<CreateTaskResponseType | ErrorResponseType> => {
    try {
        const response = await http.post("/admin/tasks/create/farminvite/", {
            key: data.key,
            quantity: data.quantity,
            reward: data.reward,
        });

        return response.data;
    } catch (error: any) {
        return error.response.data;
    }
};

const TasksContoller = {
    getTasks,
    completeTask,
    restoreTask,
    createMysteryTask,
    createSubTask,
    getTask,
    editSubTask,
    editFarmInviteTask,
    createFarmInviteTask,
    getInactiveSubTasks
}

export default TasksContoller;