import $ from "jquery";
import tonwallet from "../../../assets/img/modal/ton-wallet.png";
import tonlogo from "../../../assets/img/modal/ton-logo.png";
import { useUser } from "../../../context/UserContext";
import Input from "../../admin/tasks/inputs/Input";
import { useEffect, useState } from "react";
import UserController from "../../../api/user";

export default function Wallet() {
    const { user } = useUser();
    const [wallet, setWallet] = useState<string>("");
    const [message, setMessage] = useState<string>("");

    useEffect(() => {
        if (user) {
            setWallet(user.wallet || "");
        }
    }, [user]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!user) return;

        const response = await UserController.setWallet(user.tg_id, wallet);

        if (response.error) {
            return setMessage(response.message);
        }

        setMessage("");

        $(".wallet-modal").removeClass("-open");
    }

    if (!user) return null;

    return <div className="modal-window -task wallet-modal">
        <div className="close-modal -svg" onClick={() => $(".wallet-modal").removeClass("-open")}>
            <svg id="Capa_1" enableBackground="new 0 0 320.591 320.591" viewBox="0 0 320.591 320.591" xmlns="http://www.w3.org/2000/svg"><g><g id="close_1_"><path d="m30.391 318.583c-7.86.457-15.59-2.156-21.56-7.288-11.774-11.844-11.774-30.973 0-42.817l257.812-257.813c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875l-259.331 259.331c-5.893 5.058-13.499 7.666-21.256 7.288z"></path><path d="m287.9 318.583c-7.966-.034-15.601-3.196-21.257-8.806l-257.813-257.814c-10.908-12.738-9.425-31.908 3.313-42.817 11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414-6.35 5.522-14.707 8.161-23.078 7.288z"></path></g></g></svg>
        </div>
        <div className="up-part-of-modal -modal-mb20">
            <div className="left-part">
                <div className="alert-of-modal"><br /></div>
                <div className="modal-header">
                    Подключи свой<br />
                    кошелек сети TON
                </div>
            </div>
            <div className="right-part">
                <img src={tonlogo} alt="" />
            </div>

        </div>
        <div className="down-part-of-modal">
            <Input title="" state={wallet} setState={setWallet} placeholder="Твой адрес кошелька" />
            {message && <div className="err" style={{ "margin": "-10px 0 15px" }}>{message}</div>}

            <form className="modal-btn -start-game" onSubmit={handleSubmit}>
                <button type="submit">
                    <span>Сохранить</span>
                </button>
            </form>
        </div>
        <div className="inner-part-of-modal">
            <div className="main-signature -modal-mb20">
                Зарабатывай до выхода токена CardClash, будьте в топе игроков и получайте денежные вознаграждения!
            </div>
            <div className="modal-image-wrap -modal-mb20">
                <img src={tonwallet} alt="" />
            </div>
            {user.wallet && <div className="amoult-info -fz14">
                <div className="signature-for-amoult-info">
                    Статус кошелька:
                </div>
                <div className="value-for-amoult-info">
                    Добавлен игроком
                </div>
            </div>}
        </div>
    </div>

}