import { User } from "../../../types";
import dealer from "../../../assets/img/dealer.svg";
import { useLevels } from "../../../context/LevelsContext";
import $ from "jquery";
import { formatNumber, getUserLevel, sliceUsername } from "../../../utils";

interface PlayerListProps {
    players: User[];
    label: "Мои" | "Топ";
}

export default function PlayerList({ players, label }: PlayerListProps) {
    const { levels } = useLevels();
    const tableClassName = label === "Мои" ? "-my-players" : "-top-players";

    if (!levels) return null;

    return <div className={`players-block-info -demo-show-all ${tableClassName}`}>
        <div className="up-part-platers-block">
            <div className="players-amoult">
                {label} игроки {` `}
                <span>{players.length}</span>
            </div>
            <div className="text-btn -second-style -open-show-all -demo-open-show-all" onClick={() => $(`.${tableClassName}`).addClass("-show-all")}>
                Показать всех <span>{`>`}</span>
            </div>
            <div className="text-btn -second-style -close-show-all -demo-close-show-all" onClick={() => $(`.${tableClassName}`).removeClass("-show-all")}>
                Свернуть <span>{`>`}</span>
            </div>
        </div>
        <div className="inner-list-players">
            {players.map((player, index) => {
                const level = levels.find(level => level.key === player.levelKey);

                if (!level) return null;

                return <div className="player-stroke" key={player.id}>
                    <div className="left-part-player">
                        <div className="icon-div">
                            <img src={dealer} alt="" />
                        </div>
                        <div className="all-info-player">
                            <div className="name-of-player">{sliceUsername(player.tg_firstname + " " + (player.tg_lastname ?? ""))}</div>
                            <div className="down-part-of-info">
                                <div className="info-value">{level.level_name} {getUserLevel(level.user_level, player.balance)}</div>
                                <div className="info-value">Игроков <span>{player.friends.length}</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="right-part-player">
                        {label === "Мои" && <div className="my-coins-proc">+{formatNumber(player.bonus ?? player.balance * 0.1)}</div>}

                        <span>{formatNumber(player.balance)}</span>
                        <div className="coin-icon -w18"></div>
                    </div>
                </div>
            })}
        </div>
    </div>
}