import { ErrorResponseType, GetSettingsResponseType, UpdateSettingsResponseType } from "../../types";
import http from "./index";

const updateBonus = async (bonus: string): Promise<UpdateSettingsResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.put(`/admin/settings`, {
            bonus,
        });

        return data;
    } catch (error: any) {
        return error.response.data;
    }
}

const getSettings = async (): Promise<GetSettingsResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.get(`/admin/settings`);
        
        return data;
    } catch (error: any) {
        return error.response.data;
    }
}

const SettingsController = {
    updateBonus,
    getSettings
}

export default SettingsController;