import { useUser } from "../../../context/UserContext";
import { copyToClipboard } from "../../../utils";
import decor from "../../../assets/img/ref-decor.png";
import TgShareButton from "../TgShareButton";
import { useState } from "react";

export default function Header() {
    const { user } = useUser();
    const [copyState, setCopyState] = useState("copy");

    if (!user) return null;

    return <div className="up-of-inner-main-part -ref-link">
        <div className="ref-link-header">
            Твоя реферальная ссылка:
        </div>
        <div className="ref-link-wrap">
            <div className="ref-link">
                <div className="ref-link-left">
                    <div className="icon-div">
                        <img src={decor} alt="" />
                    </div>
                    <div className="txt-link">
                        {process.env.REACT_APP_REF_LINK! + user.tg_id}
                    </div>
                </div>

                <div className="copy-link" onClick={() => {
                    copyToClipboard(process.env.REACT_APP_REF_LINK! + user.tg_id)
                    setCopyState("copied");
                    setTimeout(() => setCopyState("copy"), 5000);
                }}>
                    <svg id="_Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 469.33 469.34">
                        <path className="cls-1" d="M128,42.67C128,19.1,147.1,0,170.67,0h256c23.56,0,42.67,19.1,42.67,42.67v256c0,23.56-19.1,42.67-42.67,42.67h-21.33c-11.78,0-21.33-9.55-21.33-21.33v-160c0-41.24-33.43-74.67-74.67-74.67h-160c-11.78,0-21.33-9.55-21.33-21.33v-21.33ZM0,170.67c0-23.56,19.1-42.67,42.67-42.67h256c23.56,0,42.67,19.1,42.67,42.67v256c0,23.56-19.1,42.67-42.67,42.67H42.67c-23.56,0-42.67-19.1-42.67-42.67v-256Z" />
                    </svg>
                    <div className="signature">
                        {copyState}
                    </div>
                </div>
            </div>

            <TgShareButton />
        </div>
        <div className="description-for-up-part">
            С каждого приведенного игрока ты получаешь <span>10%</span> от его фарма фишек.
        </div>
    </div>
}