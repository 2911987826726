import { useState } from "react";
import Header from "./Header";
import Combo from "./Combo";
import CardList from "./CardList";
import { cardArray, ComboInfo, shuffle } from "../../../utils";
import { useUser } from "../../../context/UserContext";
import UserController from "../../../api/user";
import SwipeReward from "./SwipeReward";
import useCardSwipe from "../../../hooks/useCardSwipe";
import { CurrentCardType } from "../../../types";
import { useAutoFarm } from "../../../context/AutoFarmContext";
import { levelClassNames } from "../../../utils";

export default function Cards() {
    const { user, setUser, currentGameReward, setCurrentGameReward } = useUser();
    const [currentCardReward, setCurrentCardReward] = useState(0);
    const { autoFarmStarted } = useAutoFarm();
    const [cards, setCards] = useState(cardArray);

    const { comboInfo } = useCardSwipe(updateBalance, user, autoFarmStarted, shuffleCards);

    function shuffleCards() {
        setCards(shuffle(cards));
    }

    function updateBalance(currentCard: CurrentCardType, combo: ComboInfo | null) {
        return (async () => {
            const reward = combo ? combo.reward : currentCard.reward * user!.level.multiplier;

            setCurrentCardReward(reward);

            setCurrentGameReward(currentGameReward => currentGameReward + reward);

            const updatedUser = await UserController.increaseBalance(user!.tg_id, reward);

            if (!updatedUser.error) {
                setUser(updatedUser.user);
            }
        })();
    }

    if (!user) return null;

    return (
        <>
            <Header currentGameReward={currentGameReward} />

            <div className="inner-main-part">
                <div className={`cards-part ${levelClassNames[user.level.user_level - 1]}`}>
                    <Combo comboInfo={comboInfo} />

                    <CardList cards={cards} />

                    <div className="decor-cards-back"></div>
                </div>

                <SwipeReward currentGameReward={currentGameReward} currentCardReward={currentCardReward} />
            </div>
        </>
    );
}
