import { useState } from "react";
import AboutProject from "../modal/AboutProject";
import ActiveTab from "../tabs/ActiveTab";
import Tabs from "../tabs/Buttons";
import ContainerHeader from "./Header";
import { useUser } from "../../../context/UserContext";
import SubscriptionModal from "../modal/Subscription";
import OnlineTimer from "../OnlineTime";
import Wallet from "../modal/Wallet";

export default function Body() {
    const { user } = useUser();
    const [showModal, setShowModal] = useState(false);

    if (!user) return null;

    return <div className="app-part">
        <div className="inner-app-part">
            <ContainerHeader user={user} setShowModal={setShowModal} />

            <div className="main-part">
                {showModal && <AboutProject setShowModal={setShowModal} />}

                <div className="bg-item"></div>

                <ActiveTab />

                <Tabs />

                <SubscriptionModal />

                <Wallet />
            </div>
        </div>

        <OnlineTimer userId={user.tg_id} />
    </div>
}