import { TelegramShareButton } from "react-share";
import { useUser } from "../../context/UserContext";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import SettingsController from "../../api/admin/settings";

export default function TgShareButton() {
    const { user } = useUser();
    const [bonus, setBonus] = useState("");

    const getSettings = async () => {
        const response = await SettingsController.getSettings();

        if (response.error) return toast(response.message);

        setBonus(response.settings.refBonus?.toString() ?? "");
    }

    useEffect(() => {
        getSettings();
    }, []);

    if (!user || !bonus) return null;

    return <TelegramShareButton
        url={process.env.REACT_APP_REF_LINK! + user.tg_id}
        title={`Присоединяйся к игре Card Clash!\n\nПо ссылке действует бонус в ${bonus} фишек.`}
        style={{ width: "100%" }}
    >
        <div className="modal-btn ref-link-btn">
            <span>
                Отправить ссылку
                <div className="icon-div-ref-link">
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 409.71 409.64">
                        <path className="cls-1" d="M374.56,35.09c-46.9-46.78-122.81-46.78-169.7,0l-11.32,11.31c-6.25,6.25-6.25,16.38,0,22.63,0,0,0,0,0,0l22.62,22.62c6.25,6.25,16.38,6.25,22.63,0l11.32-11.32c21.88-21.86,57.34-21.85,79.2.03,21.85,21.87,21.85,57.3,0,79.17l-56.58,56.56c-20.53,20.3-53.09,21.8-75.4,3.47l-12.28-10.25c-6.78-5.66-16.87-4.76-22.54,2.02,0,0,0,0,0,0l-20.51,24.57c-5.66,6.78-4.75,16.87,2.03,22.54l12.28,10.25c47.57,40.05,117.93,36.86,161.67-7.34l56.58-56.56c46.86-46.87,46.85-122.84,0-169.7h0Z" />
                        <path className="cls-1" d="M193.55,317.93c-6.25-6.25-16.38-6.25-22.63,0l-11.32,11.32c-21.88,21.86-57.34,21.85-79.2-.03-21.85-21.87-21.85-57.3,0-79.17l56.58-56.56c20.52-20.32,53.09-21.82,75.4-3.47l12.28,10.25c6.78,5.66,16.87,4.76,22.54-2.02,0,0,0,0,0,0l20.51-24.57c5.66-6.78,4.75-16.87-2.03-22.54l-12.28-10.25c-47.57-40.06-117.93-36.86-161.67,7.34l-56.58,56.56c-46.86,46.86-46.86,122.84,0,169.71s122.84,46.86,169.71,0l11.32-11.31c6.25-6.25,6.25-16.38,0-22.63,0,0,0,0,0,0l-22.62-22.62Z" />
                    </svg>
                </div>
            </span>
        </div>
    </TelegramShareButton>
}