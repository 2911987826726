import { Task } from "../../../../types";
import Status from "./layout/Status";
import $ from "jquery";

interface Props {
    task: Task;
}

export default function Invite({ task }: Props) {
    return <div className={`block-info -aic -referal ${task.parentTaskUuid ? '-done-task' : ''}`}>
        <div className="left-part">
            <div className="icon-div">
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 377.92">
                    <path className="cls-1" d="M238.24,234.36h35.52v50.69h-35.52v-50.69Z" />
                    <path className="cls-1" d="M105.17,191.53l43.89-25.34,17.76,30.76-43.89,25.34-17.76-30.76Z" />
                    <path className="cls-1" d="M345.16,196.97l17.76-30.76,43.89,25.34-17.76,30.76-43.89-25.34Z" />
                    <path className="cls-1" d="M52.97,122.38c0,271.49,406.07,270.69,406.07,0V52.97H52.97v69.41ZM291.42,293.87c0,4.88-3.95,8.83-8.83,8.83h-53.18c-4.88,0-8.83-3.95-8.83-8.83v-68.34c0-4.88,3.95-8.83,8.83-8.83h53.18c4.88,0,8.83,3.95,8.83,8.83v68.34ZM364.11,146.49l59.19,34.17c4.22,2.43,5.67,7.84,3.23,12.06l-26.59,46.05c-2.44,4.23-7.85,5.66-12.06,3.23l-59.19-34.17c-4.22-2.43-5.67-7.84-3.23-12.06l26.59-46.05c2.44-4.22,7.84-5.67,12.06-3.23h0ZM186.93,80.37c4.88,0,8.83,3.95,8.83,8.83,0,80.94,120.48,80.07,120.48,0,0-4.88,3.95-8.83,8.83-8.83s8.83,3.95,8.83,8.83c0,103.84-155.79,104.04-155.79,0,0-4.88,3.95-8.83,8.83-8.83ZM88.7,180.66l59.19-34.17c4.23-2.44,9.62-.99,12.06,3.23l26.59,46.05c2.44,4.22.99,9.62-3.23,12.06l-59.19,34.17c-4.19,2.42-9.61,1.02-12.06-3.23l-26.59-46.05c-2.44-4.22-.99-9.62,3.23-12.06h0Z" />
                    <path className="cls-1" d="M485.52,0H26.48C11.86,0,0,11.86,0,26.48v95.89c0,340.82,512,340.62,512,0V26.48C512,11.86,500.14,0,485.52,0ZM476.69,122.38c0,293.52-441.38,295.16-441.38,0V44.14c0-4.88,3.95-8.83,8.83-8.83h423.72c4.88,0,8.83,3.95,8.83,8.83v78.24Z" />
                </svg>
            </div>

            <div className="main-txt -fz14">
                Пригласи {task.quantity} друзей <br /> по своей ссылке
            </div>
        </div>

        <div className="decor-icon -w100proc">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 377.92">
                <path className="cls-1" d="M238.24,234.36h35.52v50.69h-35.52v-50.69Z" />
                <path className="cls-1" d="M105.17,191.53l43.89-25.34,17.76,30.76-43.89,25.34-17.76-30.76Z" />
                <path className="cls-1" d="M345.16,196.97l17.76-30.76,43.89,25.34-17.76,30.76-43.89-25.34Z" />
                <path className="cls-1" d="M52.97,122.38c0,271.49,406.07,270.69,406.07,0V52.97H52.97v69.41ZM291.42,293.87c0,4.88-3.95,8.83-8.83,8.83h-53.18c-4.88,0-8.83-3.95-8.83-8.83v-68.34c0-4.88,3.95-8.83,8.83-8.83h53.18c4.88,0,8.83,3.95,8.83,8.83v68.34ZM364.11,146.49l59.19,34.17c4.22,2.43,5.67,7.84,3.23,12.06l-26.59,46.05c-2.44,4.23-7.85,5.66-12.06,3.23l-59.19-34.17c-4.22-2.43-5.67-7.84-3.23-12.06l26.59-46.05c2.44-4.22,7.84-5.67,12.06-3.23h0ZM186.93,80.37c4.88,0,8.83,3.95,8.83,8.83,0,80.94,120.48,80.07,120.48,0,0-4.88,3.95-8.83,8.83-8.83s8.83,3.95,8.83,8.83c0,103.84-155.79,104.04-155.79,0,0-4.88,3.95-8.83,8.83-8.83ZM88.7,180.66l59.19-34.17c4.23-2.44,9.62-.99,12.06,3.23l26.59,46.05c2.44,4.22.99,9.62-3.23,12.06l-59.19,34.17c-4.19,2.42-9.61,1.02-12.06-3.23l-26.59-46.05c-2.44-4.22-.99-9.62,3.23-12.06h0Z" />
                <path className="cls-1" d="M485.52,0H26.48C11.86,0,0,11.86,0,26.48v95.89c0,340.82,512,340.62,512,0V26.48C512,11.86,500.14,0,485.52,0ZM476.69,122.38c0,293.52-441.38,295.16-441.38,0V44.14c0-4.88,3.95-8.83,8.83-8.83h423.72c4.88,0,8.83,3.95,8.83,8.83v78.24Z" />
            </svg>
        </div>

        <Status reward={task.reward} onClick={() => $('.modal-window.-add-a-friends.invite-modal').addClass('-open')} />
    </div>
}