import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { AutoFarmType } from "../types";
import { useUser } from "./UserContext";
import { useError } from "./ErrorContext";
import AutoFarmController from "../api/autofarm";

interface AutoFarmContextType {
    autoFarmStarted: AutoFarmType | null;
    setAutoFarmStarted: (value: AutoFarmType | null) => void;
    getAutoFarmStatus: (tg_id: string) => void;
}

const AutoFarmContext = createContext<AutoFarmContextType>(null!);

export function AutoFarmProvider({ children }: { children: React.ReactNode }) {
    const { tg_id, setUser } = useUser();
    const { setError } = useError();

    const [autoFarmStarted, setAutoFarmStarted] = useState<AutoFarmType | null>(null);

    const getAutoFarmStatus = useCallback(async (tg_id: string) => {
        const response = await AutoFarmController.statusAutoFarm(tg_id);

        if (response.error) return setError(response.message);

        setAutoFarmStarted(response.status);

        if (response.user) setUser(response.user);
    }, [setError, setUser]);

    useEffect(() => {
        if (!tg_id) return;

        getAutoFarmStatus(tg_id);
    }, [tg_id, setError, setAutoFarmStarted, getAutoFarmStatus]);

    return <AutoFarmContext.Provider value={{ autoFarmStarted, setAutoFarmStarted, getAutoFarmStatus }}>{children}</AutoFarmContext.Provider>;
}

export const useAutoFarm = () => useContext(AutoFarmContext);

