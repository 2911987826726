import { useEffect, useRef, useState } from "react";
import { useTasks } from "../../../context/TasksContext";
import { Task } from "../../../types";
import stacks from "../../../assets/img/modal/stacks.png";
import TasksController from "../../../api/tasks";
import { useUser } from "../../../context/UserContext";
import $ from "jquery";

export default function MysteryTask() {
    const { user, setUser } = useUser();
    const { tasks } = useTasks();
    const inputRef = useRef<HTMLInputElement>(null);
    const [mysteryTask, setMysteryTask] = useState<Task | undefined>(undefined);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (tasks) {
            setMysteryTask(tasks.find(task => task.key === 'mystery'));
        }
    }, [tasks]);

    if (!tasks || !mysteryTask || !user) return null;

    const closeModal = () => $('.modal-window.-guess-a-riddle').removeClass('-open');

    const handleSubmit = async () => {
        setError(null);

        if (!inputRef.current) return;

        const response = await TasksController.mysteryTaskCheckAnswer(mysteryTask.uuid, inputRef.current.value, user.tg_id);

        if (response.error) {
            return setError(response.message);
        }

        setUser(response.user);

        closeModal();
    }

    return <div className="modal-window -task -guess-a-riddle">
        <div className="close-modal -svg" onClick={closeModal}>
            <svg id="Capa_1" enableBackground="new 0 0 320.591 320.591" viewBox="0 0 320.591 320.591" xmlns="http://www.w3.org/2000/svg"><g><g id="close_1_"><path d="m30.391 318.583c-7.86.457-15.59-2.156-21.56-7.288-11.774-11.844-11.774-30.973 0-42.817l257.812-257.813c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875l-259.331 259.331c-5.893 5.058-13.499 7.666-21.256 7.288z"></path><path d="m287.9 318.583c-7.966-.034-15.601-3.196-21.257-8.806l-257.813-257.814c-10.908-12.738-9.425-31.908 3.313-42.817 11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414-6.35 5.522-14.707 8.161-23.078 7.288z"></path></g></g></svg>
        </div>
        <div className="up-part-of-modal -modal-mb20">
            <div className="left-part">
                <div className="alert-of-modal">Задание</div>
                <div className="modal-header">
                    Отгадай загадку<br />и получи фишки!
                </div>
                <div className="will-added">
                    <span>+ {mysteryTask.reward}</span>
                    <div className="coin-icon -w24"></div>
                </div>
            </div>
            <div className="right-part">
                <img src={stacks} alt="" />
            </div>

        </div>
        <div className="down-part-of-modal">
            <div className="input-wrap">
                <input type="text" placeholder="Твой ответ" ref={inputRef} />
                {error && <div className="error-msg" style={{ display: error ? 'block' : 'none' }}>{error}</div>}
            </div>
            <div className="modal-btn -start-game" onClick={handleSubmit}>
                <span>Проверить</span>
                <div className="will-added">
                    + {mysteryTask.reward}
                    <div className="coin-icon -w14"></div>
                </div>
            </div>
        </div>
        <div className="inner-part-of-modal">
            <div className="main-signature -modal-mb20">
                {mysteryTask.desc}
            </div>
            <div className="modal-image-wrap">
                <img src={`/images/${mysteryTask.image}`} alt="" />
            </div>
        </div>
    </div>

}