import { createContext, useContext, useEffect, useState } from "react";
import { TabsContextType, TabsType } from "../types";
import { useAutoFarm } from "./AutoFarmContext";
import { useUser } from "./UserContext";

export const TabsContext = createContext<TabsContextType | undefined>(undefined);

export function TabsProvider({ children }: { children: React.ReactNode }) {
    const { tg_id } = useUser();
    const [activeTab, setActiveTab] = useState<TabsType>("cards");
    const { setAutoFarmStarted, getAutoFarmStatus } = useAutoFarm();

    useEffect(() => {
        if (!tg_id) return;

        if (activeTab !== "cards") {
            setAutoFarmStarted(null);
        }
        
        getAutoFarmStatus(tg_id);
    }, [activeTab, setAutoFarmStarted, tg_id, getAutoFarmStatus]);

    return <TabsContext.Provider value={{ activeTab, setActiveTab }}>{children}</TabsContext.Provider>;
}

export const useTabs = () => {
    const context = useContext(TabsContext);

    if (!context) {
        throw new Error("useTabs must be used within a GameProvider");
    }
    return context;
};