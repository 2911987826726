import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { AdminProvider } from "../../context/AdminContext";

export default function AdminLayout() {
    const location = useLocation();
    const [stylesLoaded, setStylesLoaded] = useState(false);

    useEffect(() => {
        if (location.pathname.startsWith("/admp") && !stylesLoaded) {
            // require("../../admin.css");
            const link = document.createElement("link");
            link.rel = "stylesheet";
            link.href = "/assets/admin.css";
            document.head.appendChild(link);
        }

        setStylesLoaded(true);

        return () => {
            setStylesLoaded(false);
        };
    }, [location.pathname, stylesLoaded])

    if (!stylesLoaded) {
        return <div></div>;
    }

    return <AdminProvider>
        <Outlet />
    </AdminProvider>
}