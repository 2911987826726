import cardClash from "../../../assets/img/modal/card-clash.png";
import cards from "../../../assets/img/modal/cards.png";
import bronzeDeck from "../../../assets/img/cards/decks/bronze-decks.png"
import goldDeck from "../../../assets/img/cards/decks/gold-decks.png"
import platinumDeck from "../../../assets/img/cards/decks/platinum-decks.png"
import startDeck from "../../../assets/img/cards/decks/start-decks.png"
import cosmoDeck from "../../../assets/img/cards/decks/cosmo-decks.png"
import { useLevels } from "../../../context/LevelsContext";
import { useUser } from "../../../context/UserContext";
import { Level } from "../../../types";

const images = [
    startDeck,
    bronzeDeck,
    goldDeck,
    platinumDeck,
    cosmoDeck
];

interface Props {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

export default function LevelDecksModal({ setShowModal }: Props) {
    const { user } = useUser();
    const { levels } = useLevels();

    return <div className="modal-window -level-decks" style={{ display: "flex" }}>
        <div className="close-modal -svg" onClick={() => setShowModal(false)}>
            <svg id="Capa_1" enableBackground="new 0 0 320.591 320.591" viewBox="0 0 320.591 320.591" xmlns="http://www.w3.org/2000/svg"><g><g id="close_1_"><path d="m30.391 318.583c-7.86.457-15.59-2.156-21.56-7.288-11.774-11.844-11.774-30.973 0-42.817l257.812-257.813c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875l-259.331 259.331c-5.893 5.058-13.499 7.666-21.256 7.288z" /><path d="m287.9 318.583c-7.966-.034-15.601-3.196-21.257-8.806l-257.813-257.814c-10.908-12.738-9.425-31.908 3.313-42.817 11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414-6.35 5.522-14.707 8.161-23.078 7.288z" /></g></g></svg>
        </div>
        <div className="up-part-of-modal -modal-mb20">
            <div className="left-part">
                <img src={cardClash} alt="" />
                <div className="modal-header">Уровни колод</div>
            </div>
            <div className="right-part">
                <img src={cards} alt="" />
            </div>

        </div>
        <div className="main-signature">
            Фармите монеты-фишки<br />и повышайте свой уровень!
        </div>
        <div className="inner-part-of-modal">
            <div className="decks-list">
                {levels?.map((level: Level) => {
                    return <div className={"decks-item" + (level.key === user?.levelKey ? " -active -actual" : "")} key={level.deck_name}>
                        <div className="left-part">
                            <div className="img-of-decks">
                                <img src={images[level.user_level - 1]} alt="" />
                            </div>
                            <div className="level-and-name-of-decks">
                                <span>{level.level_name} {level.user_level === 1 ? "Ур.1 - Ур.10" : ""}</span>
                                <span>{level.deck_name}</span>
                            </div>
                        </div>
                        <div className="right-part">
                            <div className="to-amoult">
                                <div className="up-part-to-amoult">{level.key === "diler_legenda" ? "Безгранично" : "До " + level.end_balance}</div>
                                <div className="farm-level">
                                    + фарм <span>x{level.multiplier}</span>
                                </div>
                            </div>
                            <div className="coin-icon -w22"></div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    </div>
}