import { Task } from "../../../../types";
import Status from "./layout/Status";
import $ from "jquery";

interface Props {
    task: Task;
}

export default function Mystery({ task }: Props) {
    return <div className={`block-info -aic -mistery ${task.parentTaskUuid ? '-done-task' : ''}`}>
        <div className="left-part">
            <div className="icon-div">
                <svg id="Capa_1" enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m320.267 512h-192.8c-8.284 0-15-6.716-15-15 0-150.727 83.026-217.772 165.061-299.807l40.52-40.52c-9.418 1.238-19.394 1.859-29.913 1.859-42.209 0-78.652-16.066-112.467-16.066-36.025 0-65.334 29.309-65.334 65.333 0 8.284-6.716 15-15 15h-80.334c-8.284 0-15-6.716-15-15v-192.799c0-8.284 6.716-15 15-15h80.333c8.284 0 15 6.716 15 15v16.88c31.337-20.503 71.164-31.88 113.533-31.88 67.306 0 99.749 32.134 160.667 32.134 9.447 0 17.134-7.687 17.134-17.134 0-8.284 6.716-15 15-15h80.333c8.284 0 15 6.716 15 15 0 74.389-35.759 126.525-73.617 181.724-48.333 70.472-103.116 150.347-103.116 300.276 0 8.284-6.716 15-15 15z" /></svg>
            </div>

            <div className="main-txt -fz14">
                Отгадай еженедельную <br /> бонусную загадку
            </div>
        </div>

        <div className="decor-icon -w100proc">
            <svg id="Capa_1" enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m320.267 512h-192.8c-8.284 0-15-6.716-15-15 0-150.727 83.026-217.772 165.061-299.807l40.52-40.52c-9.418 1.238-19.394 1.859-29.913 1.859-42.209 0-78.652-16.066-112.467-16.066-36.025 0-65.334 29.309-65.334 65.333 0 8.284-6.716 15-15 15h-80.334c-8.284 0-15-6.716-15-15v-192.799c0-8.284 6.716-15 15-15h80.333c8.284 0 15 6.716 15 15v16.88c31.337-20.503 71.164-31.88 113.533-31.88 67.306 0 99.749 32.134 160.667 32.134 9.447 0 17.134-7.687 17.134-17.134 0-8.284 6.716-15 15-15h80.333c8.284 0 15 6.716 15 15 0 74.389-35.759 126.525-73.617 181.724-48.333 70.472-103.116 150.347-103.116 300.276 0 8.284-6.716 15-15 15z" /></svg>
        </div>

        <Status reward={task.reward} onClick={() => $('.modal-window.-guess-a-riddle').addClass('-open')} />
    </div>
}