// @ts-nocheck
import { useEffect, useRef, useState } from "react";
import { AutoFarmType, User } from "../types";
import $ from "jquery";
import { CardType, ComboInfo, checkCombination } from "../utils";

type UpdateBalance = (card: { reward: number; value: string }, combo: ComboInfo | null) => Promise<void>;

const useCardSwipe = (updateBalance: UpdateBalance, user: User | null, autoFarmStarted: AutoFarmType | null, shuffleCards: () => void) => {
    const animatingRef = useRef(false);
    const hasReleasedRef = useRef(false);
    const cardsCounterRef = useRef(0);
    const $cardRef = useRef(null);
    const $cardRejectRef = useRef(null);
    const $cardLikeRef = useRef(null);
    const pullDeltaXRef = useRef(0);
    const [cardsHistory, setCardsHistory] = useState<CardType[]>([]);
    const [comboInfo, setComboInfo] = useState<ComboInfo | null>(null);

    useEffect(() => {
        // if (autoFarmStarted) return;

        const decisionVal = 80;
        const numOfCards = 52;

        function pullChange() {
            const pullDeltaX = pullDeltaXRef.current;
            const $card = $cardRef.current;
            const deg = pullDeltaX / 5;
            const opacity = pullDeltaX / 10;
            const rejectOpacity = opacity >= 0 ? 0 : Math.abs(opacity);
            const likeOpacity = opacity <= 0 ? 0 : opacity;

            $card.css("transform", `translateX(${pullDeltaX}px) rotate(${deg}deg)`);
            $cardRejectRef.current.css("opacity", rejectOpacity);
            $cardLikeRef.current.css("opacity", likeOpacity);
        }

        async function release() {
            if (hasReleasedRef.current) return;
            hasReleasedRef.current = true;

            const pullDeltaX = pullDeltaXRef.current;

            if (Math.abs(pullDeltaX) >= decisionVal) {
                const directionClass = pullDeltaX >= decisionVal ? "to-right" : "to-left";
                $cardRef.current.addClass(directionClass);

                const currentCard = {
                    reward: $cardRef.current.attr("data-reward"),
                    value: $cardRef.current.attr("data-value"),
                    type: $cardRef.current.attr("data-type"),
                };

                const history = cardsHistory.slice(-4).concat(currentCard);

                setCardsHistory(history);

                const combo = checkCombination(history, user.level.multiplier);

                if (combo) {
                    setComboInfo(combo);

                    setTimeout(() => {
                        setComboInfo(null);
                    }, 1500);
                }

                await updateBalance(currentCard, combo);

                $(".big-coins-amoult").addClass("-added-action");

                setTimeout(() => {
                    $(".big-coins-amoult").removeClass("-added-action");
                }, 1500);
            }

            if (Math.abs(pullDeltaX) >= decisionVal) {
                $cardRef.current.addClass("inactive");

                cardsCounterRef.current++;

                setTimeout(() => {
                    $cardRef.current.addClass("below").removeClass("inactive to-left to-right");

                    if (cardsCounterRef.current === numOfCards) {
                        cardsCounterRef.current = 0;
                        $(".demo__card").removeClass("below");
                        shuffleCards();
                        setCardsHistory([]);
                    }
                }, 0);
            }

            if (Math.abs(pullDeltaX) < decisionVal) {
                $cardRef.current.addClass("reset");
            }

            setTimeout(() => {
                $cardRef.current.attr("style", "").removeClass("reset").find(".demo__card__choice").attr("style", "");
                pullDeltaXRef.current = 0;
                animatingRef.current = false;
                hasReleasedRef.current = false;
            }, 300);
        }

        const handleMouseDown = (e) => {
            if (animatingRef.current || hasReleasedRef.current) return;

            const $card = $(e.currentTarget);
            $cardRef.current = $card;
            $cardRejectRef.current = $(".demo__card__choice.m--reject", $card);
            $cardLikeRef.current = $(".demo__card__choice.m--like", $card);

            const startX = e.pageX || e.originalEvent.touches[0].pageX;

            $(document).on("mousemove touchmove", handleMouseMove(startX));
            $(document).on("mouseup touchend", handleMouseUp);
        };

        const handleMouseMove = (startX) => (e) => {
            if (hasReleasedRef.current) return;

            const x = e.pageX || e.originalEvent?.touches[0]?.pageX;
            pullDeltaXRef.current = x - startX;
            if (!pullDeltaXRef.current) return;
            pullChange();
        };

        const handleMouseUp = () => {
            $(document).off("mousemove touchmove mouseup touchend");
            if (!pullDeltaXRef.current) return; // prevents from rapid click events
            release();
        };

        $(document).on("mousedown touchstart", ".demo__card:not(.inactive)", handleMouseDown);

        return () => {
            $(document).off("mousedown touchstart", ".demo__card:not(.inactive)", handleMouseDown);
        };
    }, [updateBalance, user, autoFarmStarted, shuffleCards, cardsHistory]);

    return { comboInfo };
};

export default useCardSwipe;
