import dealer from "../../../assets/img/dealer.svg";
import storm from "../../../assets/img/storm.svg";
import { User } from "../../../types";
import { formatNumber, getUserLevel, sliceUsername } from "../../../utils";
import $ from "jquery";

interface Props {
    user: User;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ContainerHeader({ user, setShowModal }: Props) {
    return <>
        <div className="up-part-profile">
            <div className="left-part">
                <div className="avatar-dealer">
                    <img src={dealer} alt="" />
                </div>
                <div className="name-with-level">
                    <div className="level">
                        {user.level.level_name} {getUserLevel(user.level.user_level, user.balance)}
                    </div>
                    <div className="tg-name">{sliceUsername(user.tg_username)}</div>
                </div>
            </div>
            <div className="right-part">
                <div className="amoult-of-coins">
                    <div className="coins">
                        <span>{formatNumber(user.balance)}</span>
                        <div className="coin-icon -w24"></div>
                    </div>
                    <button className="boost" onClick={() => {
                        $(".wallet-modal").addClass("-open");
                    }}>
                        <img src={storm} alt="" />
                        <span>Wallet</span>
                    </button>
                </div>

                <div className="text-btn -open-about-project" onClick={() => setShowModal(true)}>Про проект</div>
            </div>
        </div>
    </>
}