import { useState } from "react";
import { toast } from "react-toastify";
import TasksContoller from "../../api/admin/tasks";
import useFileUpload from "../../hooks/useFileUpload";
import { Link } from "react-router-dom";
import Upload from "../../components/admin/tasks/inputs/Upload";
import Input from "../../components/admin/tasks/inputs/Input";
import Area from "../../components/admin/tasks/inputs/Area";

export default function CreateSub() {
    const [reward, setReward] = useState("");
    const [text, setText] = useState('');
    const [answer, setAnswer] = useState("");
    const { file: fileIcon, handleFileClick: handleFileIconClick, handleFileChange: handleFileIconChange, fileInputRef: fileInputIconRef, removeFile: removeFileIcon } = useFileUpload();
    const { file: fileBanner, handleFileClick: handleFileBannerClick, handleFileChange: handleFileBannerChange, fileInputRef: fileInputBannerRef, removeFile: removeFileBanner } = useFileUpload();
    const [modalTitle, setModalTitle] = useState("Подпишитесь на телеграм канал наших партнеров посвященный ставкам!");

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!fileIcon) {
            return toast('Пожалуйста, загрузите иконку.');
        }

        if (!fileBanner) {
            return toast('Пожалуйста, загрузите баннер.');
        }

        const response = await TasksContoller.createSubTask({
            icon: fileIcon,
            banner: fileBanner,
            reward,
            text,
            answer,
            modalTitle
        });

        if (response.error) return toast(response.message);

        return toast(response.message);
    };

    return <section className="main-part-dinamic">
        <div className="wrap-of-dinamic">
            <div className="up-part-of-dinamic">
                <Link to="/admp/tasks" className="btn-s-gradient">
                    <span>&lt; Назад</span>
                </Link>
            </div>
            <div className="dinamic-inner-header mb40" style={{ maxWidth: "600px" }}>
                <div>
                    <div>⚠️ ВНИМАНИЕ! Добавление нового задания с подпиской заменяет модальное уведомление в игре.</div><br />
                    <div>Бот с игрой должен находится в администраторах тг паблика или группы.</div>
                </div>
            </div>
            <div className="main-header-of-page">Подписка на тг канал или группу</div>

            <form className="dinamic-content -cmr-users -other-pages-content -verify-page" onSubmit={handleSubmit}>
                <Upload file={fileIcon} handleFileClick={handleFileIconClick} handleFileChange={handleFileIconChange} fileInputRef={fileInputIconRef} removeFile={removeFileIcon}>
                    <div className="data-for-photo">
                        <span>Иконка телеграм ресурса. Максимум 1 мегабайт.</span>
                        <span>Тип файла: JPG, PNG, HEIC, JPEG</span>
                    </div>
                </Upload>

                <Upload file={fileBanner} handleFileClick={handleFileBannerClick} handleFileChange={handleFileBannerChange} fileInputRef={fileInputBannerRef} removeFile={removeFileBanner}>
                    <div className="data-for-photo">
                        <span>Баннер загадки. Максимум 4 мегабайт.</span>
                        <span>Тип файла: JPG, PNG, HEIC, JPEG</span>
                    </div>
                </Upload>

                <Input title="Вознаграждение" state={reward} setState={setReward} placeholder="Количество фишек" />

                <Input title="Ссылка на группу" state={answer} setState={setAnswer} placeholder="Ссылка в формате https://t.me/groupname" />

                <Area title="Заголовок модального окна" placeholder="Введите заголовок модального окна" state={modalTitle} setState={setModalTitle} />

                <Area title="Название ТГ канала / группы" placeholder="Введите название ТГ канала / группы" state={text} setState={setText} />

                <button className="btn-more-s -save-btn" type="submit">
                    Сохранить
                </button>
            </form>
        </div>
    </section>
}