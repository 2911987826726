import { usePreventCollapse } from "../../../hooks/usePreventCollapse";
import { Task, TasksType, User } from "../../../types";
import Farm from "./items/Farm";
import Invite from "./items/Invite";
import Mystery from "./items/Mystery";
import Subscription from "./items/Subscription";

interface Props {
    tasks: TasksType;
    user: User;
}

export default function Body({ tasks, user }: Props) {
    usePreventCollapse();

    const renderTask = (task: Task) => {
        switch (task.key) {
            case 'mystery':
                return <Mystery task={task} key={task.uuid} />;
            case 'sub':
                return <Subscription task={task} key={task.uuid} />;
            case 'invite':
                return <Invite task={task} key={task.uuid} />;
            case 'farm':
                return <Farm task={task} key={task.uuid} />;
            default:
                return null
        }
    }

    return <div className="inner-main-part -aifs -tasks">
        {tasks.map(task => {
            return renderTask(task);
        })}
    </div >
}