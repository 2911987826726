import Error from "../Error";
import Body from "./Body";
import { useUser } from "../../../context/UserContext";
import { useError } from "../../../context/ErrorContext";

export default function Container() {
    const { loading } = useUser();
    const { error } = useError();

    if (error) return <Error>{error}</Error>;

    return <div className="container">
        {loading && <div className="prelouder">
            <div className="coin-icon"></div>
        </div>}

        <Body />
    </div>
}

