interface CardProps {
    card: {
        type: string;
        value: string;
        coins: number;
    }
}

export default function CardItem({ card }: CardProps) {
    return <div className={`demo__card -start-cards -${card.type}-${card.value}`} data-reward={card.coins} data-value={card.value} data-type={card.type}>
        <div className="demo__card__top">
            <div className="demo__card__img">
            </div>
        </div>

        <div className="demo__card__choice m--reject">
            <div className="added-amoult-coins">+{card.coins}</div>
        </div>
        <div className="demo__card__choice m--like">
            <div className="added-amoult-coins">+{card.coins}</div>
        </div>
        <div className="demo__card__drag"></div>
    </div>
}