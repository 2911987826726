import { useState } from "react";
import LevelDecksModal from "../modal/LevelDecks";
import AutoFarm from "./AutoFarm";
import StartGameHint from "./StartGameHint";
import Progress from "./Progress";
import { useAutoFarm } from "../../../context/AutoFarmContext";

interface HeaderProps {
    currentGameReward: number;
}

export default function Header({ currentGameReward }: HeaderProps) {
    const [showModal, setShowModal] = useState(false);
    const { autoFarmStarted } = useAutoFarm();

    return <>
        {showModal && <LevelDecksModal setShowModal={setShowModal} />}

        <div className="up-of-inner-main-part">
            <Progress setShowModal={setShowModal} />

            {(currentGameReward > 0 || autoFarmStarted) && <AutoFarm />}

            {(currentGameReward === 0 && !autoFarmStarted) && <StartGameHint />}
        </div>
    </>
}